import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const SecondPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="mb-16 md:mb-28 text-white bg-helpappy-red  ">
      <div className="container flex flex-col items-center text-center px-8 py-16 mx-auto">
        <Link to="/">
          <StaticImage
            src="../images/logo.png"
            alt="helpappy logo"
            layout="fixed"
            placeholder="blurred"
            width={200}
            quality={100}
            className="mb-12"
          />
        </Link>

        <p className="text-2xl font-bold mt-4 mb-2 md:mb-0">
          Helpappy, your very own life admin PA.
        </p>
        <p className="text-2xl font-bold mb-12">
          You’ll have less to do but more will get done.
        </p>
        <p>
          <a className="text-xl" href="mailto:support@helpappy.com">
            support@helpappy.com
          </a>
        </p>
      </div>
    </div>

    <div className="container mx-auto px-8 mb-16 md:mb-28 md:max-w-6xl">
      <h1 className="text-2xl font-bold mt-4 mb-2">Helpappy Privacy Promise</h1>

      <h2 className="text-lg font-bold mt-4 mb-2">Our Privacy Promise</h2>
      <p className="mb-2">
        We promise that we’ll tell you how we use your data and we’ll use it to
        ease your life admin burden. Plus, we’ll make sure we collect and store
        your data securely.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">
        Safety is our number one priority
      </h2>
      <p className="mb-2">
        We have to collect and keep a little data about you, like your name,
        email address and payment details. We promise to collect, process, store
        and share your data safely and securely.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">
        We use data to make your life easier.
      </h2>
      <p className="mb-2">
        We can use the contact details you share with us to inform and update
        you about changes to upcoming plans. Plus by listening to what our
        customers tell us, we can make changes, to make things easier where we
        can.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">
        You're in control of your data
      </h2>
      <p className="mb-2">
        If you would like to stop receiving marketing from us, you can email us
        at support@helpappy.com to unsubscribe.
      </p>
      <p className="mb-8 border-b-2	pb-8">
        If the information we hold on you is wrong, you can email us at
        support@helpappy.com to let us know what needs updating and we will
        correct it.
      </p>
      <h2 className="text-2xl font-bold mt-4 mb-2">
        Our Privacy Policy in Full
      </h2>
      <p className="mb-2">
        Helpappy is committed to protecting your safety and privacy, and takes
        its responsibilities regarding the security of customer information very
        seriously. This privacy policy explains what personal data we collect
        about you, how and why we use it, who we disclose it to, and how we
        protect your privacy.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">
        1. Who is responsible for your data
      </h2>
      <p className="mb-2">
        Our Privacy Policy applies to the personal data that Helpappy collects
        and uses.
      </p>
      <p className="mb-2">
        References in this Privacy Policy to “Helpappy”, “we”, “us” or “our”
        mean Helpappy Limited (a company registered in England and Wales with
        registration no 11034424 and registered office at 167 Uxbridge Road,
        London, United Kingdom, W7 3TH). We control the ways your personal data
        are collected and the purposes for which your personal data are used by
        Helpappy and are the “data controller” for the purposes of the UK Data
        Protection Act 1998 (as amended or replaced), the General Data
        Protection Regulation (EU) 2016/679 (the “GDPR”) and other applicable
        European data protection legislation.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">
        2. Personal data we collect about you
      </h2>
      <p className="mb-2">
        When using the term “personal data” in our Privacy Policy, we mean
        information that relates to you and allows us to identify you, either
        directly or in combination with other information that we may hold. Your
        personal data may include for example your name, your contact details,
        information relating to your family or information on how you use our
        website or you interact with us.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">
        Categories of data we collect
      </h2>
      <p className="mb-2">
        We may collect and process the following categories of information about
        you:
      </p>

      <table className="table-fixed mt-4 mb-2 text-left min-w-full">
        <thead className="bg-gray-50 dark:bg-gray-700 ">
          <tr>
            <th className="w-1/3 p-2">What</th>
            <th className="w-1/3 p-2">Why</th>
            <th className="w-1/3 p-2">Basis of Processing</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-2">
              Your name and surname and your contact details (email address,
              telephone number and postal address)
            </td>
            <td className="p-2">When you create an account on our website</td>
            <td className="p-2">Performance of a contract</td>
          </tr>
          <tr className="bg-gray-50 dark:bg-gray-700 ">
            <td className="p-2">
              Information about your Life Admin needs e.g. holiday preferences,
              food preferences, schedule, the car you drive
            </td>
            <td className="p-2">When you use a Helpappy PA</td>
            <td className="p-2">Performance of a contract</td>
          </tr>
          <tr>
            <td className="p-2">
              Information about your family and friends e.g. names and dates of
              birth, their gift preferences.
            </td>
            <td className="p-2">
              When you use a Helpappy PA to organise something for another
              member of your family
            </td>
            <td className="p-2">Performance of a contract</td>
          </tr>
          <tr className="bg-gray-50 dark:bg-gray-700 ">
            <td className="p-2">
              Information about your and your family's health
            </td>
            <td className="p-2">
              For example when you use a Helpappy PA to book a doctor or dentist
              appointment
            </td>
            <td className="p-2">Performance of a contract</td>
          </tr>
          <tr>
            <td className="p-2">
              Information about your transaction, including your payment card
              details
            </td>
            <td className="p-2">
              When you purchase Helpappy services or when Helpappy purchases
              goods and/or services on your behalf
            </td>
            <td className="p-2">Performance of a contract</td>
          </tr>
          <tr className="bg-gray-50 dark:bg-gray-700 ">
            <td className="p-2">
              The communications you exchange with us (for example, your emails,
              letters, calls, or your messages on our online chat service)
            </td>
            <td className="p-2">
              When you contact Helpappy or one of our PAs or we contact you
            </td>
            <td className="p-2">Performance of a contract</td>
          </tr>
          <tr>
            <td className="p-2">
              Your posts and messages on social media directed to Helpappy
            </td>
            <td className="p-2">When you interact with us on social media</td>
            <td className="p-2">Performance of a contract</td>
          </tr>
          <tr className="bg-gray-50 dark:bg-gray-700 ">
            <td className="p-2">Your feedback</td>
            <td className="p-2">When you complete customer surveys</td>
            <td className="p-2">Performance of a contract</td>
          </tr>
        </tbody>
      </table>

      <h2 className="text-lg font-bold mt-4 mb-2">
        3. Special categories of data
      </h2>
      <p className="mb-2">
        In the course of providing services to you, we may collect information
        that could reveal your racial or ethnic origin, sexual orientation,
        political opinions physical or mental health, religious or philosophical
        beliefs, trade union membership or genetic or biometric data. Such
        information is considered “special categories of data” under the GDPR
        and other data protection laws. We only collect this information where
        you have given your explicit consent, it is necessary, or you have
        deliberately made it public. You have the right to withdraw such
        consent.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">
        4. Sharing your personal data
      </h2>
      <p className="mb-2">
        Your personal data may be shared with our PAs. It may also be disclosed
        to a third party who acquires us or substantially all of our assets.
        Your personal data may be shared with a third party when you ask us to
        book or pay for goods or services on your behalf.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">4. Marketing</h2>
      <p className="mb-2">
        We may from time to time send you emails or messages marketing similar
        products of services. We believe that this fulfils our legitimate
        interest as a business of hoping to convince our valued customers to use
        our services more. We will always give you the right to opt-out of
        receiving these messages.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">6. Deleting your data</h2>
      <p className="mb-2">
        Once you choose to stop being a Helpappy customer, we will delete your
        personal data within 60 days unless we consider there is a legal reason
        not to do so.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">7. Your rights</h2>
      <p className="mb-2">
        You have rights in respect of your personal data. These include the
        right to:
      </p>
      <ul className="list-disc ml-4 pl-4">
        <li>Request access to and rectification of your data;</li>
        <li>Request deletion of your data;</li>
        <li>Request we restrict processing your data;</li>
        <li>Object to processing of your data; and</li>
        <li>Request we transfer your data to you or a third party.</li>
      </ul>
      <p className="mb-2">
        We will comply with the GDPR and other relevant data protection laws in
        relation to each of these rights.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">8. Cookies</h2>
      <p className="mb-2">
        In order to improve our services, to provide you with more relevant
        content and to analyse how visitors use our website and app, we may use
        technologies, such as cookies, pixels or tracking software. Please be
        aware that in most cases we will not be able to identify you from the
        information we collect using these technologies.
      </p>
      <p className="mb-2">
        For example, we use software to monitor customer traffic patterns and
        website usage to help us develop the design and layout of the website in
        order to enhance the experience of the visitors to our website. This
        software does not enable us to collect any personal data. In addition,
        in order to understand how our customers interact with the emails and
        the content that we send, we use pixels that allow us to know if the
        emails we send are opened or if the content of our emails is displayed
        in text or html form.
      </p>
      <p className="mb-2">
        We also use cookies in our website, mobile app or in our emails. Cookies
        are small pieces of information stored by your browser on your
        computer's hard drive. They enable you to navigate on our website or app
        and allow us to provide features such as remembering aspects of your
        last flight search to make subsequent searches faster. You can delete
        cookies if you wish; while certain cookies are necessary for viewing and
        navigating on our website or app, most of the features will be still
        accessible without cookies.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">
        9. Family/Friends of a Helpappy Customer?
      </h2>
      <p className="mb-2">
        If a Helpappy Customer uses Helpappy to book services on your behalf. In
        order to book these services, we may process your personal data that we
        have received from the Helpappy customer.
      </p>
      <h2 className="text-lg font-bold mt-4 mb-2">
        10. Right to lodge a complaint with the ICO
      </h2>
      <p lassName="text-lg font-bold mt-4 mb-2">
        You have the right to lodge a complaint with the ICO (the UK’s data
        protection supervisory body). The ICO’s website is{" "}
        <a href="https://ico.org.uk" target="_blank" rel="noopener">
          https://ico.org.uk
        </a>{" "}
        where you can find contact details and the process for making a
        complaint.
      </p>
    </div>
  </Layout>
)

export default SecondPage
